import { css } from '@amedia/brick-tokens';
export { sroStyle } from '@amedia/brick-tokens';

export const componentStyle = css({
  '&:not(:defined)': {
    visibility: 'hidden',
  },
  '--brick-player-maxheight': '100%',
  '--_b-teaser-player-radii':
    'var(--b-teaser-player-radii, var(--brick-radii-teaser))',
  '--_b-teaser-player-padding':
    'var(--brick-space-teaserYInsetM) var(--brick-space-teaserXInsetM)',
  '@bp500': {
    '--_b-teaser-player-padding':
      'var(--brick-space-teaserYInsetL) var(--brick-space-teaserXInsetL)',
    'brick-carousel &': {
      '--_b-teaser-player-padding': 0,
    },
  },
  'brick-carousel &': {
    '--_b-teaser-player-padding': 0,
  },
  position: 'relative',
  margin: 0,
  display: 'grid',
  backgroundColor: '$supportiveNoneBg',
  color: '$supportiveNoneFg',
  borderRadius: 'var(--_b-teaser-player-radii)',
  overflow: 'hidden',
  padding: 'var(--_b-teaser-player-padding)',
  '& brick-player[aspectratio]': {
    '& div:has(.is-loaded)': {
      height: '100%',
      maxHeight: '100%',
    },
  },
  '&:focus-visible::before': {
    content: '""',
    position: 'absolute',
    inset: '0',
    outline: '3px solid $utilityInteractionFocusOutline',
    outlineOffset: '-3px',
    zIndex: '2',
  },

  '& > :first-child': {
    borderRadius: '$teaserImage',
    overflow: 'hidden',
  },
  maxHeight: '100vh',

  '& .recommend-overlay': {
    '--brick-image-width': 'auto',
    display: 'none',
    opacity: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor:
      'color-mix(in srgb, var(--brick-colors-baseNeutral900) 90%, transparent)',
    color: 'var(--brick-colors-baseNeutral50)',
    placeItems: 'center',
    gridTemplateRows: 'auto 1fr auto',
    zIndex: 1,
    padding: 'var(--brick-space-teaserBoxInsetM)',
    pointerEvents: 'auto' /* Capture pointer events */,
    transition: 'opacity 0.5s ease-out, display 0.5s ease-out',
    transitionBehavior: 'allow-discrete',
    '&.recommended-visible': {
      display: 'grid',
      opacity: 1,
      '@starting-style': {
        opacity: 0,
      },
    },
    '& .buttons': {
      alignSelf: 'flex-start',
      justifySelf: 'flex-end',
      paddingBottom: 'var(--brick-space-x1s)',
    },
    '& .buttons:last-child': {
      justifySelf: 'flex-start',
    },

    '& a.recommend-poster': {
      display: 'flex',
      boxSizing: 'border-box',
      alignItems: 'center',
      borderRadius: 'var(--brick-radii-teaser)',
      width: 'calc(100% - var(--brick-space-x4))',
      overflow: 'hidden',
      backgroundColor: '$utilitySecondarySubtleBg',
      color: '$utilitySecondaryFg',
      textDecoration: 'none',
      padding: 'var(--brick-space-teaserStackAM)',
      gap: 'var(--brick-space-teaserStackAM)',
      maxWidth: '500px',
      '& .image-column': {
        '--brick-image-height': '100%',
        flex: '1 1 auto',
        aspectRatio: '1 / 1',
        boxSizing: 'border-box',
        height: '100%',
        maxHeight: '125px',
        maxWidth: '25%',
        borderRadius: 'var(--brick-radii-teaserImage)',
        overflow: 'hidden',
      },
      '& brick-icon-v2': {
        backgroundColor: 'var(--brick-colors-supportiveBlackBg)',
        color: 'var(--brick-colors-supportiveBlackFg)',
        alignItems: 'center',
        padding: 0,
        height: '100%',
        aspectRatio: '1 / 1',
        display: 'flex',
        justifyContent: 'center',
      },
      '& brick-image-v3, & brick-icon-v2': {
        boxSizing: 'border-box',
        '& img': {
          borderRadius: 'inherit',
        },
      },
      //container querie clamp for fontsize
      '& .text-column': {
        containerType: 'inline-size',
        containerName: 'textcolumn',
        flex: '1',
        paddingBlock: 'var(--brick-space-teaserContentYInsetM)',
        paddingInline: 'var(--brick-space-teaserContentXInsetM)',
      },
      '@container textcolumn (max-width: 190px)': {
        '& .duration': {
          display: 'none',
        },
        '& h3': {
          WebkitLineClamp: 3,
        },
      },
      '& h3': {
        margin: 0,
        padding: 0,
        fontFamily: 'var(--brick-fonts-baseHeadlineXxs)',
        fontSize: 'var(--brick-fontSizes-baseHeadlineXxs)',
        fontWeight: 'var(--brick-fontWeights-baseHeadlineXxs)',
        lineHeight: 'var(--brick-lineHeights-baseHeadlineXxs)',
        letterSpacing: 'var(--brick-letterSpacings-baseHeadlineXxs)',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        '@supports (font-size: clamp(1rem, 1cqi, 2rem)) and (container-type: inline-size)':
          {
            fontSize:
              'clamp(var(--brick-fontSizes-baseHeadlineXxs), 5cqi, var(--brick-fontSizes-baseHeadlineM))',
          },
      },
      '& h3:hover': {
        textDecoration: 'underline',
      },
      '& .duration': {
        margin: 0,
        fontFamily: '$baseMetaS',
        fontSize: '$baseMetaS',
        fontWeight: '$baseMetaS',
        lineHeight: '$baseMetaS',
        letterSpacing: '$baseMetaS',
        color: '$baseSubtleFg',
        '@supports (font-size: clamp(1rem, 1cqi, 2rem)) and (container-type: inline-size)':
          {
            fontSize:
              'clamp(var(--brick-fontSizes-baseMetaS), 1cqi, var(--brick-fontSizes-baseMetaM))',
          },
      },
    },
  },
});
